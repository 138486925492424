import styled from '@emotion/styled';
import MuiCard from '@mui/material/Card';

import { theme } from '../../../styles/theme';

const CardWrapper = styled.div(() => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '6px'
}));

interface CardProps {
  className?: string;
  wrapperClass?: string;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <CardWrapper className={`h-full ${props.wrapperClass || ''}`}>
      <MuiCard {...props} />
    </CardWrapper>
  );
};

export default Card;
