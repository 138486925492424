import { Transform } from 'class-transformer';
import { IsEmail, IsOptional, IsString, IsUUID, Length } from 'class-validator';

import { SignupStatus } from '../enum/auth.enum';

export class UserSignupReqDto {
  @IsEmail()
  email: string;

  @IsOptional()
  @IsString()
  @Length(1, 100)
  @Transform(({ value }) => value.trim())
  name?: string;

  @IsOptional()
  @IsUUID(4)
  invitationId?: string | null;
}

export class UserSignupResDto {
  status: SignupStatus;

  token?: string;
}
