import FormControlLabel from '@mui/material/FormControlLabel'
import MuiRadio from '@mui/material/Radio'

export interface RadioProps {
  value?: string
  children: JSX.Element | string | number
  disabled?: boolean
  checked?: boolean
}

const Radio: React.FC<RadioProps> = (props) => {
  return (
    <FormControlLabel
      control={<MuiRadio />}
      label={props.children}
      {...props}
    />
  )
}

export default Radio
