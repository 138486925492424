import { ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from '@ui';

import ErrorBoundary from './modules/common/error-boundary';
import LocaleProvider from './modules/locale/components/locale-provider';
import AllRoutes from './routes';
import { theme } from './styles/theme';

function App() {
  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <LocaleProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={1}>
              <AllRoutes />
            </SnackbarProvider>
          </ThemeProvider>
        </LocaleProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}

export default App;
