import { forwardRef, ReactNode } from 'react';

import MuiTypography from '@mui/material/Typography';

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption';

export type TypographyColor =
  | 'textPrimary'
  | 'textSecondary'
  | 'textTertiary'
  | 'textAlt'
  | 'primary'
  | 'success'
  | 'error'
  | 'action'
  | 'caption';

const ColorMapping: Record<TypographyColor, string> = {
  textPrimary: 'text.primary',
  textSecondary: 'text.secondary',
  textTertiary: 'text.tertiary',
  textAlt: 'text.alt',
  error: 'error.main',
  primary: 'primary.main',
  success: 'success.main',
  action: 'grey.700',
  caption: 'grey.800'
};

type ElementType = HTMLHeadingElement | HTMLParagraphElement | HTMLSpanElement;

export interface TypographyProps {
  variant?: Variant;
  color?: TypographyColor;
  fontWeight?: 'fontWeightBold' | 'fontWeightMedium' | 'fontWeightRegular';
  className?: string;
  children?: ReactNode | ReactNode[];
  noWrap?: boolean;
  component?: keyof JSX.IntrinsicElements;
}

const Typography: React.ForwardRefRenderFunction<ElementType, TypographyProps> = (
  { children, color, ...props },
  ref
) => {
  return (
    <MuiTypography color={color ? ColorMapping[color] : undefined} ref={ref} {...props}>
      {children}
    </MuiTypography>
  );
};

export default forwardRef(Typography);
