import styled from '@emotion/styled'

interface Props {
  iconUrl: string
  size: string | number
  color: string
}

export const CustomIconStyled = styled.div<Props>(
  ({ iconUrl, size, color }) => ({
    display: 'inline-block',
    backgroundColor: color,
    WebkitMask: `url(${iconUrl}) no-repeat center / contain`,
    mask: `url(${iconUrl}) no-repeat center / contain`,
    width: size,
    height: size,
    lineHeight: 'inherit'
  })
)
