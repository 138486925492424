import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  campaigns: Object[];
}

const initialState: AuthState = {
  campaigns: []
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {}
});

export default campaignSlice.reducer;
