import { useState } from 'react';

import { useForm } from '@form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { HttpClientUtil } from '@http-client';
import { Alert, Button, FormFields, Hr, TextField, Typography } from '@ui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useAppDispatch } from '../../../redux/hooks';
import FormWrapper from '../body/form-wrapper';
import ContinueWithGoogleButton from '../componets/continue-with-google';
import { UserSignupReqDto } from '../dto/create-user.dto';
import { SignupStatus } from '../enum/auth.enum';
import { AuthRepo } from '../service/auth-repository';

const RegisterForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const form = useForm<UserSignupReqDto>({
    resolver: classValidatorResolver(UserSignupReqDto)
  });

  async function onSubmit(data: UserSignupReqDto) {
    try {
      const resData = await dispatch(
        AuthRepo.signup({ ...data, invitationId: searchParams.get('id') })
      ).unwrap();

      if (resData.status === SignupStatus.EmailSent) {
        navigate(routes.auth.checkMailToSetPassword);
      } else {
        const seachParams = `?email=${data.email}&token=${resData.token}`;
        navigate({ pathname: routes.auth.varifyEmail, search: seachParams });
      }
    } catch (error) {
      const msg = HttpClientUtil.getErrorMsgKey(error);
      setError(msg);
    }
  }

  return (
    <FormWrapper
      heading='Register Account'
      description={
        <>
          Hey! Enter your details to create <br /> your account
        </>
      }
      footer={{
        title: 'Already have an account?',
        buttonText: 'Login Now',
        onClick: () => {
          navigate(routes.auth.login);
        }
      }}
    >
      <div className='mb-6'>
        <ContinueWithGoogleButton />
      </div>

      <div className='flex items-center'>
        <Hr />
        <Typography variant='subtitle2' color='textTertiary' className='min-w-fit mx-5 text-center'>
          Or register with
        </Typography>
        <Hr />
      </div>

      <form className='mt-6' onSubmit={form.handleSubmit(onSubmit)}>
        <FormFields>
          <TextField placeholder='Enter Email' type='email' {...form.register('email')} />
        </FormFields>

        <Button className='mt-8' variant='contained' color='primary' type='submit' fullWidth={true}>
          Create Account
        </Button>

        {error && (
          <div className='my-8'>
            <Alert severity='error'>{error}</Alert>
          </div>
        )}
      </form>
    </FormWrapper>
  );
};

export default RegisterForm;
