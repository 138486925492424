interface Obj {
  [key: string]: Obj;
}

export class ObjectUtil {
  public static get(obj: Obj, path: string): unknown {
    const val = path.split('.').reduce((acc, c) => acc?.[c as keyof typeof acc], obj);
    return val;
  }

  public static areFieldValuesChanged(
    initialState: Record<string, string>,
    currentState: Record<string, string>
  ): boolean {
    for (const key in currentState) {
      if (initialState[key] !== currentState[key]) {
        return true;
      }
    }
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static compareObjects(obj1: Record<string, any>, obj2: Record<string, any>): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      const areObjects = ObjectUtil.isObject(val1) && ObjectUtil.isObject(val2);

      if (
        (areObjects && !ObjectUtil.compareObjects(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isObject(obj: any): boolean {
    return obj != null && typeof obj === 'object';
  }
}
