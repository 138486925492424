import { useState } from 'react';

import { HttpClientUtil } from '@http-client';
import { useLocale } from '@locale';
import { useSnackbar } from '@ui';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { routes } from '../../../routes/routes';
import { SetPasswordReqDto, VerifyEmailReqDto } from '../dto/password-reset.dto';
import { AuthRepo } from '../service/auth-repository';

import SetPasswordForm from './set-password-form';

const VerifyEmailPage: React.FC = () => {
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useLocale();

  async function setPassword(data: SetPasswordReqDto) {
    const token = searchParam.get('token');
    const email = searchParam.get('email');

    if (!token || !email) {
      setError('Invalid request');
      return;
    }

    const reqData: VerifyEmailReqDto = {
      password: data.password,
      token,
      email
    };

    try {
      await AuthRepo.verifyEmail(reqData);
      enqueueSnackbar(formatMessage('signup_success_msg'), { variant: 'success' });
      navigate(routes.auth.login);
    } catch (error) {
      const msg = HttpClientUtil.getErrorMsgKey(error);
      setError(msg);
    }
  }

  return <SetPasswordForm onSubmit={setPassword} error={error} />;
};

export default VerifyEmailPage;
