import { lazy } from 'react';

import { Route, Routes } from 'react-router-dom';

import CheckMailSetPassword from './forms/check-mail-set-password';
import LoginForm from './forms/login-form';
import RegisterForm from './forms/register-form';
import RequestResetPasswordForm from './forms/request-reset-password';
import ResetPasswordPage from './forms/reset-password.page';
import VerifyEmailPage from './forms/set-password-first-time-form';

const GoogleRedirect = lazy(() => import('./forms/google-redirect'));

const AuthRoutes: React.FC = () => {
  return (
    <div className='w-screen h-screen flex justify-center items-center'>
      <div className='w-[574px]'>
        <Routes>
          <Route path='login' element={<LoginForm />} />
          <Route path='google/redirect' element={<GoogleRedirect />} />
          <Route path='register' element={<RegisterForm />} />
          <Route path='register/verify-email' element={<VerifyEmailPage />} />
          <Route path='password' element={<RequestResetPasswordForm />} />
          <Route path='password/set' element={<ResetPasswordPage />} />
          <Route path='check-mail-set-password' element={<CheckMailSetPassword />} />
        </Routes>
      </div>
    </div>
  );
};

export default AuthRoutes;
