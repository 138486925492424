import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';

export function useScreen() {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return { isMobileScreen, isTabletScreen };
}
