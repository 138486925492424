import { ReactNode } from 'react';

import styled from '@emotion/styled';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';

const DialogStyled = styled((props: MuiDialogProps) => <MuiDialog {...props} />)(() => ({
  '& .MuiDialog-paper': {
    margin: '16px !important',
    width: 'calc(100% - 32px)',
    borderRadius: '12px',

    '&.MuiDialog-paperWidthSm': {
      maxWidth: '400px !important'
    }
  }
}));

interface DialogProps {
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const Dialog: React.FC<DialogProps> = (props) => {
  return <DialogStyled {...props} />;
};

export default Dialog;
