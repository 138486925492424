import { memo } from 'react'

import { useTheme } from '@mui/material/styles'

import { SizeMapping, Size } from './constants/size.constants'
import { getIcon, IconName } from './icon.paths'
import { IconColor } from './interface/color.interface'
import { CustomIconStyled } from './styles'

interface IconProps {
  size?: Size
  color?: IconColor
  className?: string
  iconKey: IconName
  onClick?: () => void
}

const Icon: React.FC<IconProps> = ({ size, color, iconKey, ...props }) => {
  const { palette } = useTheme()

  const _color = (() => {
    switch (color) {
      case 'inherit':
        return 'currentColor'
      case 'action':
        return palette.grey[700]
      case 'disabled':
        return palette.grey[500]
      case 'default':
        return palette.grey[900]
      default:
        return palette[color!].main
    }
  })()
  const _size = SizeMapping[size!]

  return (
    <CustomIconStyled
      iconUrl={getIcon(iconKey)}
      size={_size}
      color={_color}
      {...props}
    />
  )
}

Icon.defaultProps = {
  size: 'normal',
  color: 'inherit'
}

export type { IconName }
export default memo(Icon)
