import { Button, Image } from '@ui';

import { Env } from '../../../env/env';

const ContinueWithGoogleButton: React.FC = () => {
  function handleGoogleLogin() {
    const path = `${Env.backendBaseUrl}/auth/google`;
    window.open(path, '_top', 'width=500,height=600');
  }

  return (
    <Button
      className='p-6 text-center'
      variant='outlined'
      fullWidth={true}
      startIcon={<Image imageKey='google' />}
      onClick={handleGoogleLogin}
    >
      Continue with Google
    </Button>
  );
};

export default ContinueWithGoogleButton;
