import { Palette } from '@mui/material/styles';

export const ColorPalette: Partial<Palette> = {
  primary: {
    main: '#202A4A',
    light: '#F6F7FF',
    contrastText: '#fff',
    dark: '#4a63fe'
  },
  success: {
    main: '#2CCD59',
    light: '#EFF9F4',
    contrastText: '#fff',
    dark: '#14A35E'
  },
  error: {
    main: '#F73232',
    light: '#FDF0EE',
    contrastText: '#fff',
    dark: ''
  },
  text: {
    primary: '#1C2124',
    secondary: '#999999',
    tertiary: '#717171',
    disabled: '',
    alt: '#3A3A3A'
  },
  grey: {
    50: '',
    100: '#FBFCFD',
    200: '#F1F3F7',
    300: '#E4E7EB',
    400: '',
    500: '#C1C7CD',
    600: '',
    700: '#9DA4AD',
    800: '#697077',
    900: '#1C2124',
    A100: '#EFF1FE',
    A200: '',
    A400: '',
    A700: ''
  },
  common: {
    white: '#fff',
    black: '#000'
  },
  background: {
    default: '#FBFBFD',
    paper: '#fff'
  }
};
