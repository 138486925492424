import { useState } from 'react';

import styled from '@emotion/styled';
import MuiAccordion, { AccordionProps as Props } from '@mui/material/Accordion';

import { accordionContext } from './accordion.context';

const StyledAccordion = styled((props: Props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  },
  borderRadius: '6px'
}));

interface AccordionProps {
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
  defaultExpanded?: boolean;
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ children, onChange, defaultExpanded = false }) => {
  const [isAccordionDetailOpen, setIsAccordionDetailOpen] = useState(defaultExpanded ?? false);

  return (
    <accordionContext.Provider value={{ isAccordionDetailOpen }}>
      <StyledAccordion
        onChange={(e, expanded) => {
          onChange?.(e, expanded);
          setIsAccordionDetailOpen(expanded);
        }}
        expanded={isAccordionDetailOpen}
      >
        {children!}
      </StyledAccordion>
    </accordionContext.Provider>
  );
};

export default Accordion;
