import styled from '@emotion/styled';
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  GridEventListener,
  GridEvents,
  GridLocaleText
} from '@mui/x-data-grid';

interface BaseDataGridProps {
  showBorderBottom?: boolean;
  hideColumnHeader?: boolean;
}

const BaseDataGrid = styled(MuiDataGrid)<BaseDataGridProps>(
  ({ theme, showBorderBottom = false, hideColumnHeader = false }) => ({
    border: 'none',
    '& .MuiDataGrid-row': {
      ':hover': {
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadius
      },
      ...(!showBorderBottom && {
        '&>.MuiDataGrid-cell': {
          borderBottom: 'none'
        }
      }),
      '&:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
        whiteSpace: 'break-spaces',
        wordWrap: 'break-word'
      }
    },
    '& .MuiDataGrid-columnHeaders': {
      minHeight: `${hideColumnHeader ? '0' : '64px'}!important`,
      display: `${hideColumnHeader ? 'none' : 'inline-flex'}`
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: '700'
    },
    '& .MuiDataGrid-virtualScroller': {
      marginTop: `${hideColumnHeader ? '0' : '64px'}!important`
    },
    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    '& .MuiDataGrid-overlay': {
      color: theme.palette.grey[700],
      fontsize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    },
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader': {
      outline: 'none !important'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0px 0px'
    },
    '& .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader, & .MuiDataGrid-row>.MuiDataGrid-cell':
      {
        '&:first-of-type': {
          paddingLeft: theme.spacing(6)
        },
        '&:last-child': {
          paddingRight: theme.spacing(6)
        }
      }
  })
);

type GridRowDef = Record<string, unknown> | Object;

export interface DataGridProps<T extends GridRowDef> {
  rows: T[];
  columns: GridColDef[];
  loading?: boolean;
  onRowClick?: GridEventListener<GridEvents.rowClick>;
  localeText?: Partial<GridLocaleText>;
  autoHeight?: boolean;
  pagination?: true;
  onPageChange?: (page: number) => void;
  hideFooter?: boolean;
  hideFooterPagination?: boolean;
  rowCount?: number;
  pageSize?: number;
  showBorderBottom?: boolean;
  hideColumnHeader?: boolean;
}

function DataGrid<T extends GridRowDef>(props: DataGridProps<T>): JSX.Element {
  return (
    <BaseDataGrid
      autoHeight={props.autoHeight ?? true}
      getRowHeight={() => 'auto'}
      headerHeight={48}
      hideFooterPagination
      hideFooter={true}
      hideFooterSelectedRowCount
      isRowSelectable={() => false}
      disableColumnMenu={true}
      paginationMode='server'
      classes={{
        cell: props.onRowClick ? 'cursor-pointer' : ''
      }}
      {...props}
    />
  );
}

export type { GridColDef, GridRowDef };
export default DataGrid;
