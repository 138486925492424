import { ReactNode } from 'react';

import MuiButton from '@mui/material/Button';

import Spin from '../spin';

interface ButtonProps {
  variant?: 'contained' | 'outlined' | 'text';
  disabled?: boolean;
  color?: 'primary' | 'success' | 'error';
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  fullWidth?: boolean;
  type?: 'submit' | 'reset' | 'button';
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({ children, loading, disabled, ...props }) => {
  return (
    <MuiButton
      endIcon={loading ? <Spin /> : props.endIcon}
      disabled={loading || disabled}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
