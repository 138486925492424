import { forwardRef, ReactNode } from 'react';

import { Theme } from '@emotion/react';
import MuiBox from '@mui/material/Box';
import { SxProps } from '@mui/system/styleFunctionSx';

interface BoxProps {
  sx?: SxProps<Theme>;
  children?: ReactNode;
  className?: string;
}

const BaseBox: React.ForwardRefRenderFunction<HTMLDivElement, BoxProps> = (
  { sx, children, className },
  ref
) => {
  return (
    <MuiBox sx={sx} ref={ref} className={className}>
      {children}
    </MuiBox>
  );
};

const Box = forwardRef(BaseBox);

export default Box;
