import styled from '@emotion/styled';

import IconButton from '../button/icon-button';

const BaseCheckbox = styled(IconButton)(({ theme }) => ({
  '&>div': {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    '&>input': {
      width: '20px',
      height: '20px',
      position: 'absolute',
      opacity: 0,
      zIndex: 1,
      cursor: 'pointer'
    },

    '&>span': {
      width: '20px',
      height: '20px',
      border: `2px solid ${theme.palette.grey[700]}`,
      borderRadius: '4px',
      backgroundColor: 'rgba(255, 255, 255, 0.01)',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&>.check-icon': {
        opacity: 0,
        width: 12,
        height: 12
      }
    },

    'input:checked ~ span': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      transition: 'all 0.2s ease-in-out',

      '.check-icon': {
        opacity: 1,
        color: theme.palette.common.white,
        transition: 'all 0.2s ease-in-out'
      }
    },
    'input:disabled': {
      '& ~ span': {
        background: theme.palette.primary.main
      },
      '&:checked ~ span': {
        borderWidth: '0',
        opacity: 0.85,
        '&>.check-icon': {
          color: theme.palette.common.white
        }
      }
    }
  },
  '&:hover': {
    '&>div>input:not([disabled])': {
      '~ span': {
        borderColor: theme.palette.primary.main
      },
      '&:checked ~ span': {
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.dark
      }
    }
  }
}));

export { BaseCheckbox };
