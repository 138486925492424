const IconPaths = {
  '3-dot-menu': '3-dot-menu.svg',
  'accordian-down': 'accordian-down.svg',
  'accordian-right': 'accordian-right.svg',
  'arrow-left': 'arrow-left.svg',
  'arrow-right': 'arrow-right.svg',
  'arrow-up-right': 'arrow-up-right.svg',
  'chevron-down': 'chevron-down.svg',
  'chevron-left': 'chevron-left.svg',
  'chevron-right': 'chevron-right.svg',
  'expense-wallet': 'expense-wallet.svg',
  'minus-circle': 'minus-circle.svg',
  'plus-circle': 'plus-circle.svg',
  'sales-graph': 'sales-graph.svg',
  'sort-arrow': 'sort-arrow.svg',
  'user-add': 'user-add.svg',
  attachment: 'attachment.svg',
  bin: 'bin.svg',
  calendar: 'calendar.svg',
  call: 'call.svg',
  chart: 'chart.svg',
  check: 'check.svg',
  copy: 'copy.svg',
  cross: 'cross.svg',
  download: 'download.svg',
  edit: 'edit.svg',
  error: 'error.svg',
  expenses: 'expenses.svg',
  help: 'help.svg',
  info: 'info.svg',
  loader: 'loader.svg',
  menu: 'menu.svg',
  play: 'play.svg',
  power: 'power.svg',
  sales: 'sales.svg',
  search: 'search.svg',
  setting: 'setting.svg',
  success: 'success.svg',
  upload: 'upload.svg',
  warning: 'warning.svg',
  whatsapp: 'whatsapp.svg',
  'dialog-fail': 'dialog-fail.svg',
  'dialog-success': 'dialog-success.svg',
  favicon: 'favicon.svg',
  'triangle-fail': 'triangle-fail.svg',
  google: 'google.svg',
  show: 'show.svg',
  hide: 'hide.svg',
  smartphone: 'smartphone.svg',
  desktop: 'desktop.svg',
  filter: 'filter.svg',
  'hang-up': 'hang-up.svg',
  'call-accept': 'call-accept.svg',
  'agent-busy': 'agent-busy.svg',
  'file-upload': 'file-upload.svg',
  'profile-2': 'profile-2.svg',
  logout: 'logout.svg',
  'alert-error': 'alert-error.svg',
  plus: 'plus.svg',
  'check-circle': 'check-circle.svg',
  redo: 'redo.svg',
  campaign: 'campaign.svg',
  'call-1': 'call-1.svg',
  'unread-email': 'unread-email.svg'
};

export type IconName = keyof typeof IconPaths;

export function getIcon(name: IconName): string {
  return `/icons/${IconPaths[name]}`;
}
