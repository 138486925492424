import styled from '@emotion/styled';
import MuiTooltip, { tooltipClasses, TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';

interface Props {
  color: 'dark' | 'light';
}
const BaseTooltip = styled(({ className, ...props }: MuiTooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))<Props>(({ theme, color }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: color === 'dark' ? theme.palette.primary.main : theme.palette.common.white
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: color === 'dark' ? theme.palette.primary.main : theme.palette.common.white,
    boxShadow: theme.shadow.shadow_10,
    color: theme.palette.common.white,
    ...(color === 'light' && {
      color: theme.palette.primary.main
    }),
    maxWidth: 'max-content'
  }
}));

interface TooltipProps {
  title: React.ReactNode;
  children: JSX.Element;
  open?: boolean;
  placement?: MuiTooltipProps['placement'];
  color?: 'light' | 'dark';
  onOpen?: () => void;
  onClose?: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({ color, ...props }) => {
  return <BaseTooltip arrow color={color!} {...props} />;
};

Tooltip.defaultProps = {
  color: 'dark'
};

export default Tooltip;
