import styled from '@emotion/styled';
import MuiMenuItem from '@mui/material/MenuItem';

const MenuItemStyled = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3)
}));

interface MenuItemProps {
  value?: string | number;
  className?: string;
  onClick?: () => void;
  onKeyDown?: () => void;
  children: React.ReactNode;
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  return <MenuItemStyled {...props} />;
};

export default MenuItem;
