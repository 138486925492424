interface SpinProps {
  className?: string
}

const Spin: React.FC<SpinProps> = ({ className }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`animate-spin ${className}`}
    >
      <path
        d='M8 0.947798C8 0.393592 8.44946 -0.0621474 8.99743 0.00694943C10.3309 0.175088 11.6064 0.67849 12.7023 1.4775C14.0691 2.47398 15.0864 3.87908 15.6085 5.49142C16.1305 7.10376 16.1305 8.84056 15.6085 10.4529C15.0864 12.0652 14.0691 13.4703 12.7023 14.4668C11.3355 15.4633 9.68943 16 8 16C6.31056 16 4.6645 15.4633 3.29772 14.4668C1.93094 13.4703 0.913611 12.0652 0.391548 10.4529C-0.0270598 9.16007 -0.110014 7.78723 0.142684 6.46269C0.246531 5.91837 0.817351 5.63025 1.3426 5.80151V5.80151C1.86786 5.97277 2.14629 6.53917 2.06513 7.08736C1.92951 8.00346 2.00569 8.94333 2.29366 9.83271C2.68521 11.042 3.4482 12.0958 4.47329 12.8432C5.49837 13.5905 6.73292 13.993 8 13.993C9.26708 13.993 10.5016 13.5905 11.5267 12.8432C12.5518 12.0958 13.3148 11.042 13.7063 9.83271C14.0979 8.62346 14.0979 7.32086 13.7063 6.1116C13.3148 4.90235 12.5518 3.84852 11.5267 3.10116C10.7728 2.5515 9.90555 2.18836 8.9954 2.03471C8.45077 1.94276 8 1.50201 8 0.947798V0.947798Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Spin
