import dayjs, { Dayjs } from 'dayjs';

import { DateFormat } from './date-format.enum';

type Unit = 'day' | 'minute' | 'second' | 'hour' | 'month' | 'year';

export type DateProvider = Dayjs;

type ParsableDate = Date | string | null;

export class DateX extends Date {
  constructor(parsableDate?: ParsableDate) {
    parsableDate ? super(parsableDate) : super();
  }

  private update(value: Date): void {
    this.setFullYear(value.getFullYear());
    this.setMonth(value.getMonth());
    this.setDate(value.getDate());
    this.setHours(value.getHours());
    this.setMinutes(value.getMinutes());
    this.setSeconds(value.getSeconds());
    this.setMilliseconds(value.getMilliseconds());
  }

  public format(dateFormat: DateFormat): string {
    return dayjs(this).format(dateFormat);
  }

  public add(value: number, unit: Unit): DateX {
    const newDate = dayjs(this).add(value, unit).toDate();
    this.update(newDate);
    return this;
  }

  public diff(date: ParsableDate, unit?: Unit): number {
    return dayjs(this).diff(date, unit);
  }

  public set(value: number, unit: Unit): this {
    const newDate = dayjs(this).set(unit, value).toDate();
    this.update(newDate);
    return this;
  }

  public get(unit: Unit): number {
    return dayjs(this).get(unit);
  }

  public startOf(unit: Unit): DateX {
    const newDate = dayjs(this).startOf(unit).toDate();
    this.update(newDate);
    return this;
  }

  public endOf(unit: Unit): this {
    const newDate = dayjs(this).endOf(unit).toDate();
    this.update(newDate);
    return this;
  }

  public toDateProvider(): DateProvider {
    return dayjs(this);
  }

  public isValid(): boolean {
    return dayjs(this).isValid();
  }

  public toISODate(): string {
    return this.format(DateFormat.isoDate);
  }

  public clone(): DateX {
    return new DateX(this);
  }

  public static fromProvider(provider: DateProvider): DateX {
    const dayts = new DateX(provider.toDate());
    return dayts;
  }

  public static new(val?: ParsableDate): DateX {
    return new DateX(val);
  }
}

export function dayts(value?: ParsableDate) {
  return DateX.new(value);
}
