import { useCallback } from 'react';

import { ObjectUtil } from '@utils';
import {
  FieldPath,
  FieldValues,
  InternalFieldName,
  RefCallBack,
  RegisterOptions,
  useForm as useHookForm,
  useFormContext as useHookFormContext,
  UseFormHandleSubmit,
  UseFormProps,
  UseFormRegisterReturn,
  UseFormReturn
} from 'react-hook-form';

interface UseFormRegisterReturnExtended<TFieldName extends InternalFieldName = InternalFieldName>
  extends UseFormRegisterReturn<TFieldName> {
  validationStatus?: 'error' | 'success';
  inputRef: RefCallBack;
}

type UseFormRegisterExtended<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = (
  name: TFieldName,
  options?: RegisterOptions<TFieldValues, TFieldName>
) => UseFormRegisterReturnExtended<TFieldName>;

export function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>(props?: UseFormProps<TFieldValues, TContext>): UseFormReturn<TFieldValues, TContext> {
  const form = useHookForm(props);

  const register: UseFormRegisterExtended<TFieldValues> = (...props) => {
    const registerProps = form.register(...props);

    return {
      ...registerProps,
      inputRef: registerProps.ref,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...((ObjectUtil.get(form.formState.errors as any, props[0]) as boolean) && {
        validationStatus: 'error'
      })
    };
  };

  const handleSubmit: UseFormHandleSubmit<TFieldValues> = (...props) => {
    return (e) => {
      e?.stopPropagation();
      return form.handleSubmit(...props)(e);
    };
  };

  return { ...form, handleSubmit, register } as UseFormReturn<TFieldValues, TContext>;
}

export function useFormContext<TFieldValues extends FieldValues = FieldValues>() {
  const form = useHookFormContext<TFieldValues>();

  const register: UseFormRegisterExtended<TFieldValues> = useCallback(
    (...params) => {
      const registerProps = form.register(...params);
      return {
        ...registerProps,
        inputRef: registerProps.ref,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...((ObjectUtil.get(form.formState.errors as any, params[0]) as boolean) && {
          validationStatus: 'error'
        })
      };
    },
    [form]
  );

  return { ...form, register };
}
