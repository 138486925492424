export enum DateFormat {
  isoDate = 'YYYY-MM-DD',
  dateMonthDay = 'DD MMM | ddd',
  dateMonthYearDay = 'DD MMM, YYYY | ddd',
  dateMonthYear = 'DD MMM, YYYY',
  monthYear = 'MMM YYYY',
  dateMonth = 'DD MMM',
  fullMonthYear = 'MMMM YYYY',
  hourMinutesMeridiem = 'h:mm A',
  hoursMinutes = 'HH:mm',
  timeDate = 'h:mm A | DD MMM, YYYY',
  dateTime = 'DD MMM YYYY h:mm a'
}
