import { useRef } from 'react';

import styled from '@emotion/styled';
import { useForkRef } from '@mui/material';

import Icon from '../../icon';
import IconButton from '../button/icon-button';
import { EventUtil } from '../utils/event-util';

import TextField, { TextFieldProps } from './text-field';
import { InputUtil } from '@utils';

interface BaseNumberFieldStyledProps {
  hideIncrementDecrementIcon?: boolean;
}
const BaseNumberFieldStyled = styled(TextField)<BaseNumberFieldStyledProps>(
  ({ theme, hideIncrementDecrementIcon }) => ({
    ...(!hideIncrementDecrementIcon && {
      '& .MuiInputAdornment-positionEnd': {
        marginRight: theme.spacing(1)
      }
    })
  })
);

interface NumberFieldProps extends TextFieldProps {
  numberType?: 'float' | 'int';
  hideIncrementDecrementIcon?: boolean;
}

const NumberField: React.FC<NumberFieldProps> = ({ inputRef: inputRefParent, ...props }) => {
  const isInt = props.numberType === 'int';

  const inputRef = useRef<HTMLInputElement>(null);
  const forkedRef = useForkRef(inputRefParent, inputRef);

  function handleAddition(val: number): void {
    if (!inputRef.current) {
      return;
    }

    const currVal = Number(inputRef.current.value) || 0;
    let newValue = currVal + val;
    if (newValue !== +newValue.toFixed(2)) {
      // this will handle `float` issue
      newValue = +newValue.toFixed(2);
    }

    const newVal = Math.max(newValue, 0);
    EventUtil.dispatchEvent({
      event: 'onChange',
      setter: 'value',
      target: inputRef.current,
      value: newVal
    });
  }

  return (
    <BaseNumberFieldStyled
      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (isInt) {
          e.target.value = InputUtil.parseInt(value);
        } else {
          e.target.value = InputUtil.parseNumber(value);
        }
      }}
      inputProps={{
        onKeyDown: (event) => {
          // Increment decrement with arrow keys
          if (event.key === 'ArrowDown') {
            handleAddition(-1);
          } else if (event.key === 'ArrowUp') {
            handleAddition(1);
          }
        }
      }}
      suffix={
        props.hideIncrementDecrementIcon ? null : (
          <div className='flex items-center'>
            <IconButton
              color='primary'
              onClick={() => handleAddition(-1)}
              disabled={props.disabled}
            >
              <Icon iconKey='minus-circle' />
            </IconButton>
            <IconButton color='primary' onClick={() => handleAddition(1)} disabled={props.disabled}>
              <Icon iconKey='plus-circle' />
            </IconButton>
          </div>
        )
      }
      {...props}
      inputRef={forkedRef}
    />
  );
};

NumberField.defaultProps = {
  numberType: 'int'
};

export default NumberField;
