import { combineReducers } from '@reduxjs/toolkit';

import { authSlice } from '../modules/auth/service/auth-slice';
import campaignSlice from '../modules/campaign/service/campaign-slice';
import { configReducer } from '../modules/config/service/reducer';

export const reducer = combineReducers({
  auth: authSlice.reducer,
  campaign: campaignSlice,
  config: configReducer
});
