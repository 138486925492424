export const ImagePaths = {
  google: '/icons/google.svg',
  profile: '/icons/profile.svg',
  call_pop_preview: '/icons/call_popup.svg',
  floater_preview: '/icons/floater_button.svg',
  custom_preview: '/icons/custom_button.svg',
  bruce_wayne: '/icons/bruce_wayne.svg',
  agent_busy: '/icons/agent_busy.svg',
  agent_busy_y: '/icons/agent_busy_y.svg',
  agent_group: '/icons/agent_group.svg',
  agent_call_incoming: '/icons/agent_call_incoming.png',
  agent_call_incoming_d: '/icons/agent_call_incoming_d.png',
  alert_error: '/icons/alert-error.svg',
  recording_disabled: '/icons/recording/disabled.svg',
  recording_inprocess: '/icons/recording/inprocess.svg',
  recording_no_recording: '/icons/recording/no_recording.svg',
  recording_completed: '/icons/recording/completed.svg',
  revly_logo_light: '/icons/revly-logo-light.svg',
  revly_logo_dark: '/icons/revly-logo-dark.svg',
  popup_block: '/icons/popup-block.svg'
};
