import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import '@fontsource/mulish/400.css';
import '@fontsource/mulish/500.css';
import '@fontsource/mulish/600.css';
import '@fontsource/mulish/700.css';

import { ColorPalette } from './color-palette';

let theme = createTheme({
  palette: ColorPalette,
  typography: {
    fontFamily: ['Mulish'].join(','),
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightBold: '600',
    h1: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 2
    },
    h2: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.85
    },
    h3: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.5
    },
    h4: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.25
    },

    h5: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '18px'
    },
    h6: {
      fontSize: 10,
      fontWeight: 600,
      lineHeight: '20px'
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '24px'
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px'
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px'
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px'
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      color: `${ColorPalette.grey?.[800]}`
    }
  },
  shape: {
    borderRadius: 6
  },
  shadow: {
    shadow_5: '0px 2px 5px rgba(0, 0, 0, 0.05)',
    shadow_5_reverse: '0px -2px 5px rgba(0, 0, 0, 0.05)',
    shadow_10: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    shadow_10_reverse: '0px -2px 10px rgba(0, 0, 0, 0.1)',
    shadow_20: '0px 4px 20px rgba(0, 0, 0, 0.2)'
  },
  spacing: 4,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          height: '40px',
          minWidth: 'max-content',
          textTransform: 'inherit',
          ':hover': {
            boxShadow: 'none'
          },
          '& .MuiButton-endIcon>*, & .MuiButton-startIcon>*': {
            fontSize: '16px !important'
          }
        }
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            boxShadow: 'none',
            backgroundColor: `${ColorPalette.primary?.main} !important`,
            ':hover': {
              backgroundColor: `${ColorPalette.primary?.dark} !important`
            }
          }
        },
        {
          props: { variant: 'contained', color: 'primary', disabled: true },
          style: {
            backgroundColor: `${ColorPalette?.primary?.main} !important`,
            color: '#fff !important',
            opacity: 0.3
          }
        },
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: ColorPalette.common?.white
          }
        },
        {
          props: { variant: 'outlined', color: 'primary', disabled: true },
          style: {
            borderColor: `${ColorPalette?.primary?.main} !important`,
            color: `${ColorPalette?.primary?.main} !important`,
            opacity: 0.5
          }
        },
        {
          props: { variant: 'outlined', color: 'primary', disabled: false },
          style: {
            borderColor: `${ColorPalette?.primary?.main} !important`,
            color: `${ColorPalette?.primary?.main} !important`
          }
        },
        {
          props: { variant: 'outlined', color: 'success', disabled: true },
          style: {
            borderColor: `${ColorPalette?.success?.main} !important`,
            color: `${ColorPalette?.success?.main} !important`,
            opacity: 0.5
          }
        },
        {
          props: { variant: 'outlined', color: 'success', disabled: false },
          style: {
            borderColor: `${ColorPalette?.success?.main} !important`,
            color: `${ColorPalette?.success?.main} !important`
          }
        },
        {
          props: { variant: 'outlined', color: 'error', disabled: true },
          style: {
            borderColor: `${ColorPalette?.error?.main} !important`,
            color: `${ColorPalette?.error?.main} !important`,
            opacity: 0.5
          }
        },
        {
          props: { variant: 'outlined', color: 'error', disabled: false },
          style: {
            borderColor: `${ColorPalette?.error?.main} !important`,
            color: `${ColorPalette?.error?.main} !important`
          }
        },
        {
          props: { variant: 'text' },
          style: {
            height: 'unset',
            padding: '2px 4px',
            ':hover': {
              backgroundColor: 'inherit',
              textDecoration: 'underline'
            }
          }
        },
        {
          props: { variant: 'text', disabled: true },
          style: {
            color: `${ColorPalette.primary?.main} !important`,
            opacity: 0.3
          }
        }
      ]
    },
    MuiAlert: {
      variants: [
        {
          props: { variant: 'standard', severity: 'error' },
          style: {
            backgroundColor: ColorPalette.error?.light
          }
        },
        {
          props: { variant: 'standard', severity: 'success' },
          style: {
            backgroundColor: ColorPalette.success?.light
          }
        }
      ]
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px 32px',
          borderRadius: 6
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: ColorPalette.text?.tertiary // calendar disabled date color
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            maxHeight: 'unset'
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&> .Mui-expanded ': {
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)'
          }
        }
      }
    }
  },
  mixins: {
    sideDrawer: {
      width: '224px'
    },
    appBar: {
      height: '56px'
    }
  },
  zIndex: {
    popper: 2
  }
});

theme = responsiveFontSizes(theme, {
  breakpoints: ['sm', 'md'],
  factor: 2,
  variants: ['h1', 'h2', 'h3', 'h4']
});
export { theme };
