import { useState } from 'react';

import { useForm } from '@form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { HttpClientUtil } from '@http-client';
import { Alert, Button, FormFields, TextField } from '@ui';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useAppDispatch } from '../../../redux/hooks';
import FormWrapper from '../body/form-wrapper';
import { ForgotPasswordReqDto } from '../dto/password-reset.dto';
import { AuthRepo } from '../service/auth-repository';

const ForgotPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [error, setError] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotPasswordReqDto>({
    resolver: classValidatorResolver(ForgotPasswordReqDto)
  });

  async function onSubmit(data: ForgotPasswordReqDto) {
    try {
      await dispatch(AuthRepo.forgetPassword(data)).unwrap();
      navigate(routes.auth.checkMailToSetPassword);
    } catch (error) {
      const msg = HttpClientUtil.getErrorMsgKey(error);
      setError(msg);
    }
  }

  return (
    <FormWrapper
      heading='Account Login'
      description={
        <>
          Enter the email address linked with <br /> your account.
        </>
      }
      footer={{
        title: 'Already have an account? ',
        buttonText: 'Login Now',
        onClick: () => {
          navigate(routes.auth.login);
        }
      }}
    >
      <form className='mt-6' onSubmit={handleSubmit(onSubmit)}>
        <FormFields>
          <TextField placeholder='Enter Email' type='email' {...register('email')} />
        </FormFields>

        <Button className='mt-8' variant='contained' color='primary' type='submit' fullWidth={true}>
          Request Reset
        </Button>
        {(errors.email || error) && (
          <div className='my-8'>
            <Alert severity='error'>{errors.email?.message || error} </Alert>
          </div>
        )}
      </form>
    </FormWrapper>
  );
};

export default ForgotPasswordForm;
