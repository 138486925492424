import { cloneElement, forwardRef, memo } from 'react';

import MuiIconButton from '@mui/material/IconButton';
import { IconColor } from 'lib/icon/interface/color.interface';

interface IconButtonProps {
  color?: IconColor;
  disabled?: boolean;
  children: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

const BaseIconButton: React.ForwardRefRenderFunction<HTMLButtonElement, IconButtonProps> = (
  { children, color, ...props },
  ref
) => {
  function getChildProps(): { color: string } | undefined {
    if (props.disabled) {
      return { color: 'inherit' };
    } else if (color) {
      return { color };
    }
  }

  const child = cloneElement(children, getChildProps());

  return (
    <MuiIconButton {...props} ref={ref}>
      {child}
    </MuiIconButton>
  );
};

const IconButton = forwardRef(BaseIconButton);

export default memo(IconButton);
