import { IsEmail, IsString } from 'class-validator';

import { UserDto } from '../../user/dto/user.dto';

class LoginReqDto {
  @IsEmail()
  email: string;

  @IsString()
  password: string;
}

export class LoginResDto {
  token: string;

  user: UserDto;
}

export { LoginReqDto };
