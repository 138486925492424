import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';

const MuiSwitchStyled = styled(MuiSwitch)(({ checked, theme }) => ({
  '& .MuiSwitch-track': {
    backgroundColor: `${checked ? theme.palette.primary.main : theme.palette.grey[700]} `,
    opacity: '1 !important'
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.common.white
  }
}));

interface Props {
  checked?: boolean;
  children?: JSX.Element | string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const Switch: React.FC<Props> = ({ className, children, ...restProps }) => {
  return (
    <FormControlLabel
      className={className}
      control={<MuiSwitchStyled {...restProps} />}
      label={children}
    />
  );
};

export default Switch;
