/**
 * Error Boundaries can only be class object
 * Takes care of Error thrown by the components
 * Display Fallback UI
 * */

import React, { ErrorInfo, ReactNode } from 'react';

import { Button, Typography } from '@ui';

class ErrorBoundary extends React.Component<
  { children: ReactNode },
  { hasError: boolean; error: Error | null }
> {
  constructor(props: { children: ReactNode }) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
    this.setState({ error });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='flex flex-col justify-center items-center mt-16'>
          <Typography variant='h6'>Something went wrong</Typography>
          <Typography variant='h6'>Please reload and try again</Typography>
          <Button
            className='mt-3'
            variant='contained'
            color='primary'
            onClick={() => window.location.reload()}
          >
            Reload
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
