import { Theme } from '@emotion/react'
import styled from '@emotion/styled'

interface Props {
  shadow?: keyof Theme['shadow'] | 'none'
}

const ShadowBox = styled.div<Props>(({ theme, shadow }) => {
  if (shadow === 'none') {
    return {}
  }
  return { boxShadow: theme.shadow[shadow!] }
})

ShadowBox.defaultProps = {
  shadow: 'shadow_5'
}

export default ShadowBox
