import styled from '@emotion/styled'

export const MonthSelectorWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(10),
  '&>*': {
    width: 'max-content'
  }
}))

export const MonthValue = styled.div(({ theme }) => ({
  width: '80px',
  margin: `0 ${theme.spacing(2)}`,
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  justifyContent: 'center'
}))
