import { RootState } from '../../../redux/store';
import { UserDto } from '../../user/dto/user.dto';
import { UserRole } from '../enum/user-role.enum';

import { AuthState } from './auth-slice';

export class AuthSelector {
  public static self(state: RootState): AuthState {
    return state.auth;
  }

  public static user(state: RootState): UserDto {
    return AuthSelector.self(state).user!;
  }

  public static role(state: RootState): UserRole {
    return AuthSelector.self(state).role;
  }
}
