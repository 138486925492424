import { useState } from 'react';

import { useForm } from '@form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import Icon from '@icon';
import { Button, FormFields, TextField, IconButton, Alert } from '@ui';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';

import FormWrapper from '../body/form-wrapper';
import { SetPasswordReqDto } from '../dto/password-reset.dto';

interface Props {
  onSubmit: (dto: SetPasswordReqDto) => Promise<void>;
  error?: string;
}

const SetPasswordForm: React.FC<Props> = ({ onSubmit, error }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const form = useForm<SetPasswordReqDto>({
    resolver: classValidatorResolver(SetPasswordReqDto)
  });

  return (
    <>
      <FormWrapper
        heading='Set Password'
        description={
          <>
            Enter a new password for your <br /> account
          </>
        }
        footer={{
          title: 'Already have an account? ',
          buttonText: 'Login Now',
          onClick: () => {
            navigate(routes.auth.login);
          }
        }}
      >
        <form onSubmit={form.handleSubmit(onSubmit, (err) => console.error(err))}>
          <FormFields>
            <TextField
              placeholder='Enter new Password'
              type={showPassword ? 'text' : 'password'}
              {...form.register('password')}
              suffix={
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  <Icon iconKey={showPassword ? 'show' : 'hide'} />
                </IconButton>
              }
            />
            <Button variant='contained' color='primary' type='submit'>
              Set Password
            </Button>
          </FormFields>

          {error && (
            <div className='my-8'>
              <Alert severity='error'>{error}</Alert>
            </div>
          )}
        </form>
      </FormWrapper>
    </>
  );
};

export default SetPasswordForm;
