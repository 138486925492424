import Icon from '@icon';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';

import { TextFieldValidationStatus } from '../input/interface/validation-status.interface';
import TextField from '../input/text-field';

export interface SelectProps {
  value?: string | number;
  defaultValue?: string | number;
  onChange: (event: SelectChangeEvent) => void;
  placeholder?: string;
  label?: string;
  fullWidth?: boolean;
  validationStatus?: TextFieldValidationStatus;
  children: React.ReactNode;
  className?: string;
}

const Select: React.FC<SelectProps> = ({
  label,
  validationStatus,
  value,
  defaultValue,
  className,
  ...props
}) => {
  return (
    <MuiSelect
      {...props}
      value={value?.toString() ?? ''}
      defaultValue={defaultValue?.toString() ?? ''}
      IconComponent={SelectIcon}
      MenuProps={{ disableScrollLock: true }}
      input={
        <TextField
          className={className}
          label={label}
          validationStatus={validationStatus}
          fullWidth={props.fullWidth}
        />
      }
    />
  );
};

Select.defaultProps = {
  fullWidth: true
};

interface SelectIconProps {
  className?: string;
}

const SelectIcon: React.FC<SelectIconProps> = (props) => {
  return <Icon iconKey='chevron-down' {...props} className={`${props.className} mr-2`} />;
};

export default Select;
