import { ImagePaths } from './image.paths'

interface ImageProps {
  imageKey: keyof typeof ImagePaths
  width?: string | number
  height?: string | number
  className?: string
}

const Image: React.FC<ImageProps> = ({ imageKey, ...others }) => {
  const srcPath = ImagePaths[imageKey]

  return <img src={srcPath} alt={`${imageKey}-image`} {...others} />
}

export default Image
