import { useState } from 'react';

import { useForm } from '@form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import Icon from '@icon';
import { Button, FormFields, Hr, TextField, Typography, IconButton, Alert } from '@ui';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useAppDispatch } from '../../../redux/hooks';
import { HttpClientUtil } from '../../../utils/http-client';
import FormWrapper from '../body/form-wrapper';
import ContinueWithGoogleButton from '../componets/continue-with-google';
import { LoginReqDto } from '../dto/login.dto';
import { AuthRepo } from '../service/auth-repository';

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginReqDto>({
    resolver: classValidatorResolver(LoginReqDto)
  });

  async function onSubmit(data: LoginReqDto) {
    try {
      await dispatch(AuthRepo.login(data)).unwrap();
      navigate('/');
    } catch (error) {
      const msg = HttpClientUtil.getErrorMsgKey(error);
      setError(msg);
    }
  }

  return (
    <FormWrapper
      heading='Account Login'
      description={
        <>
          Hey! Enter details to Login to <br /> your account
        </>
      }
      footer={{
        title: 'Don’t have an account?',
        buttonText: 'Register Now',
        onClick: () => {
          navigate(routes.auth.register);
        }
      }}
    >
      <div className='mb-6'>
        <ContinueWithGoogleButton />
      </div>

      <div className='flex items-center'>
        <Hr />
        <Typography variant='subtitle2' color='textTertiary' className='min-w-fit mx-5 text-center'>
          Or login with
        </Typography>
        <Hr />
      </div>

      <form className='mt-6' onSubmit={handleSubmit(onSubmit)}>
        <FormFields>
          <TextField required placeholder='Enter Email' type='email' {...register('email')} />
          <TextField
            required
            placeholder='Enter Password'
            type={showPassword ? 'text' : 'password'}
            {...register('password')}
            suffix={
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                <Icon iconKey={showPassword ? 'show' : 'hide'} />
              </IconButton>
            }
          />
        </FormFields>

        <div className='text-right mt-3'>
          <Button
            variant='text'
            fullWidth={false}
            onClick={() => navigate(routes.auth.requestResetPassword)}
          >
            <Typography fontWeight='fontWeightRegular'>Forgot Password?</Typography>
          </Button>
        </div>
        <Button className='mt-8' variant='contained' color='primary' type='submit' fullWidth={true}>
          Log In
        </Button>

        {(errors.email || error) && (
          <div className='my-8'>
            <Alert severity='error'>{errors.email?.message || error} </Alert>
          </div>
        )}
      </form>
    </FormWrapper>
  );
};

export default LoginForm;
