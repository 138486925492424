type Size = 'small' | 'normal' | 'medium' | 'large' | 'extra-large';

const SizeMapping: Record<Size, string> = {
  small: '12px',
  normal: '16px',
  medium: '20px',
  large: '24px',
  'extra-large': '32px'
};

export type { Size };
export { SizeMapping };
