import httpClient from '../../../utils/http-client';
import { createAsyncThunk } from '../../common/hooks/create-async-thunk';
import { AgentPingResDto } from '../dto/agent-ping.dto';
import { AgentStatus } from '../enum/agent-status.enum';

class AgentRepoImpl {
  public changeStatus = createAsyncThunk('agent/status', this._changeStatus);
  private async _changeStatus(status: AgentStatus) {
    await httpClient.put('agents/status', { status });
  }

  public async ping() {
    const { data } = await httpClient.get<AgentPingResDto>('agents/ping');
    return data;
  }
}

export const AgentRepo = new AgentRepoImpl();
