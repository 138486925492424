export const routes = {
  auth: {
    login: '/auth/login',
    register: '/auth/register',
    varifyEmail: '/auth/register/verify-email',
    requestResetPassword: '/auth/password',
    setPassword: '/auth/password/set',
    checkMailToSetPassword: '/auth/check-mail-set-password'
  },
  dashboard: {
    root: '/dashboard'
  },
  campaign: {
    root: '/campaign',
    create: '/campaign/new'
  },
  calls: {
    root: '/calls'
  },
  settings: {
    root: '/settings',
    profile: '/settings/profile'
  },
  support: {
    root: '/support'
  },
  profile: '/profile',
  logout: '/logout'
};
