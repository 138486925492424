import styled from '@emotion/styled';
import { Button, Image, Typography } from '@ui';

import { useScreen } from '../../../lib/hooks';

const Card = styled.div(({ theme }) => ({
  background: '#ffffff',
  boxShadow: '4px 4px 50px rgba(0, 0, 0, 0.1)',
  borderRadius: '32px',
  [theme.breakpoints.down('sm')]: {
    boxShadow: 'unset',
    borderRadius: 'unset',
    height: '100dvh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

interface FormWrapperProps {
  heading: string;
  description: React.ReactNode;
  children?: React.ReactNode;

  footer: {
    title: string;
    buttonText: string;
    onClick: () => void;
  };
}

const FormWrapper: React.FC<FormWrapperProps> = ({ children, heading, description, footer }) => {
  const { isMobileScreen } = useScreen();
  return (
    <>
      {!isMobileScreen && <Image imageKey='revly_logo_dark' className='absolute top-3 left-8' />}
      <Card className={isMobileScreen ? 'p-6' : 'p-14'}>
        {isMobileScreen && (
          <Image imageKey='revly_logo_dark' width='100px' className='mx-auto mb-6' />
        )}

        <div className='text-center'>
          <Typography variant='h1' className='text-4xl' fontWeight='fontWeightRegular'>
            {heading}
          </Typography>
          <Typography variant='subtitle1' color='textTertiary' className='mt-2'>
            {description}
          </Typography>
        </div>

        <div className='my-8'>{children}</div>

        <div className='text-center'>
          <Typography variant='body1' color='textAlt'>
            {footer.title}
          </Typography>
          <Button variant='text' fullWidth={false} color='primary' onClick={footer.onClick}>
            <Typography variant='body1' fontWeight='fontWeightBold'>
              {footer.buttonText}
            </Typography>
          </Button>
        </div>
      </Card>
    </>
  );
};

export default FormWrapper;
