import { ReactNode } from 'react';

import Icon from '../../icon';

import { NotistackSnackbarProvider } from './styles';

interface SnackbarProviderProps {
  maxSnack?: number;
  autoHideDuration?: number;
  children: ReactNode;
  containerClassName?: string;
}

const SnackbarProvider: React.FC<SnackbarProviderProps> = (props) => {
  return (
    <NotistackSnackbarProvider
      title='Message'
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      hideIconVariant={false}
      classes={{
        containerRoot: props.containerClassName
      }}
      iconVariant={{
        default: <Icon iconKey='info' color='primary' className='mr-2' size='medium' />,
        error: <Icon iconKey='error' color='error' className='mr-2' size='medium' />,
        success: <Icon iconKey='success' color='success' className='mr-2' size='medium' />
      }}
      {...props}
    />
  );
};

export default SnackbarProvider;
