import { regex } from './regex';

export class InputUtil {
  public static parseInt(str: string): string {
    return str.replace(/\D/g, '');
  }

  public static parseNumber(value: string): string {
    if (!value) return '';

    if (value.match(regex.parseNumber)) {
      return value;
    }

    return value.slice(0, -1);
  }

  public static parseNonDecimalNumber(value: string): string {
    if (!value) return '';

    if (value.match(regex.parseNonDecimalNumber)) {
      return value;
    }

    return value.slice(0, -1);
  }

  public static parseOnlyString(value: string): string {
    if (!value) return '';

    if (value.match(regex.parseOnlyString)) {
      return value;
    }

    return value.slice(0, -1);
  }
}
