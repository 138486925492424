import Icon from '../../icon';

import { TextFieldValidationStatus } from './interface/validation-status.interface';
import { FormHelperTextStyled } from './text-field.style';

interface TextFieldHelperTextProps {
  validationStatus?: TextFieldValidationStatus;
  children?: React.ReactNode;
}

const TextFieldHelperText: React.FC<TextFieldHelperTextProps> = (props) => {
  if (!props.children) {
    return null;
  }

  function getIcon(): JSX.Element | null {
    if (props.validationStatus) {
      return <Icon iconKey={props.validationStatus} />;
    }
    return null;
  }

  return (
    <FormHelperTextStyled {...props}>
      {getIcon()}
      <span>{props.children}</span>
    </FormHelperTextStyled>
  );
};

export default TextFieldHelperText;
