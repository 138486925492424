import MuiPopover, { PopoverProps as Props } from '@mui/material/Popover';

interface PopoverProps {
  id?: string;
  open?: boolean;
  onClose?: () => void;
  anchorEl?: Element | ((element: Element) => Element) | null;
  anchorOrigin?: Props['anchorOrigin'];
  transformOrigin?: Props['transformOrigin'];
  hideBackdrop?: boolean;
  children: React.ReactNode;
  disableScrollLock?: boolean;
}

const Popover: React.FC<PopoverProps> = ({ children, ...props }) => {
  const open = Boolean(props.anchorEl);

  return (
    <MuiPopover open={open} {...props}>
      {children}
    </MuiPopover>
  );
};

Popover.defaultProps = {
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'bottom'
  }
};

export default Popover;
