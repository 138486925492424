import styled from '@emotion/styled';
import { FormHelperText, InputBase } from '@mui/material';

import { TextFieldValidationStatus } from './interface/validation-status.interface';

interface Props {
  validationStatus?: TextFieldValidationStatus;
}

export const InputBaseStyled = styled(InputBase)<Props>(({ theme, validationStatus }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.common.white,
  minHeight: 40,
  borderRadius: 6,

  '&> .MuiInputBase-input': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:first-child': {
      paddingLeft: theme.spacing(4)
    },
    '&:last-child': {
      paddingRight: theme.spacing(4)
    }
  },
  '& textarea:first-of-type': {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  '& .MuiInputAdornment-positionStart': {
    marginLeft: theme.spacing(4)
  },
  '& .MuiInputAdornment-positionEnd': {
    marginRight: theme.spacing(2)
  },

  // Colors according to validation status
  ...(validationStatus
    ? { borderColor: theme.palette[validationStatus].main }
    : {
        '&:focus-within:not(.MuiInputBase-readOnly)': {
          borderColor: theme.palette.primary.main
        }
      }),

  '&.disabled': {
    backgroundColor: theme.palette.grey[300],
    borderColor: theme.palette.grey[300]
  },
  '&:hover:not(.disabled)': {
    boxShadow: `0 0 0 2px ${theme.palette.primary?.light}`
  }
}));

export const FormHelperTextStyled = styled(FormHelperText)<Props>(
  ({ theme, validationStatus }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    color: validationStatus
      ? `${theme.palette[validationStatus].main} !important`
      : theme.palette.text.tertiary
  })
);
