import { createSlice } from '@reduxjs/toolkit';

import { OrganizationDto } from '../../org/dto/organization.dto';

import { ConfigRepo } from './config.repo';

interface State {
  organization: OrganizationDto;
}

const initialState: State = {
  organization: { id: '', name: '' }
};

export const ConfigSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ConfigRepo.getUserConfig.fulfilled, (state, action) => {
      state.organization = action.payload.organization;
    });
  }
});
