import styled from '@emotion/styled';
import { AvatarProps } from '@mui/material';
import MuiAvatar from '@mui/material/Avatar';

const CustomAvatar = styled(MuiAvatar)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  color: theme.palette.primary.main,
  width: theme.spacing(9),
  height: theme.spacing(9)
}));

export interface CAvatarProps extends AvatarProps {}

const Avatar: React.FC<CAvatarProps> = ({ ...props }) => {
  return <CustomAvatar {...props} />;
};

export default Avatar;
