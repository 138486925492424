import styled from '@emotion/styled'

interface DialogBodyProps {
  width?: 'small' | 'normal' | 'large'
  height?: 'auto' | 'default'
}

const SizeMapping = {
  small: '356px',
  normal: '420px',
  large: '720px'
}

const DialogBody = styled.div<DialogBodyProps>(
  ({ width: size = 'normal', height }) => ({
    width: SizeMapping[size],
    height: height === 'auto' ? 'min-height' : '90vh',
    display: 'flex',
    flexDirection: 'column'
  })
)

export default DialogBody
