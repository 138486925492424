import { memo, useImperativeHandle, useRef } from 'react';

import { CurrencyUtil } from 'utils/currency.util';
import { InputUtil } from 'utils/input.util';

import NumberField from './number-field';
import { TextFieldProps } from './text-field';

interface CurrencyFieldProps extends TextFieldProps {
  value?: string | number;
}

const BaseCurrencyField: React.FC<CurrencyFieldProps> = ({ inputRef, ...props }) => {
  const visibleInput = useRef<HTMLInputElement>(null);

  useImperativeHandle(inputRef, () => ({
    ...visibleInput.current!,
    get value(): string {
      if (!visibleInput.current?.value) {
        return '';
      }

      const value = visibleInput.current?.value;
      if (value === '.') {
        visibleInput.current.value = '0.';
        return '0.';
      }

      return CurrencyUtil.toApi(value)?.toString() || '';
    },
    set value(newValue: string) {
      if (!visibleInput.current) {
        return;
      }
      const _newValue = CurrencyUtil.fromApi(newValue);
      const currentValue = Number(visibleInput.current.value);

      if (currentValue !== _newValue) {
        visibleInput.current.value = _newValue?.toString() || '';
      }
    }
  }));

  return (
    <NumberField
      prefix={CurrencyUtil.symbol}
      onInput={(e: React.ChangeEvent<HTMLInputElement>): void => {
        e.target.value = InputUtil.parseNumber(e.target.value);
      }}
      inputRef={visibleInput}
      hideIncrementDecrementIcon
      {...props}
    />
  );
};

const CurrencyField = memo(BaseCurrencyField);

export default memo(CurrencyField);
