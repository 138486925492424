import styled from '@emotion/styled'
import MuiPopper, { PopperProps } from '@mui/material/Popper'

const Popper = styled((props: PopperProps) => (
  <MuiPopper {...props} disablePortal />
))(({ theme }) => ({
  zIndex: `${theme.zIndex.popper} !important`
}))

export default Popper
