import httpClient from '@http-client';

import { createAsyncThunk } from '../../common/hooks/create-async-thunk';
import {
  UpdateUserProfileDto,
  UserProfileDto
} from '../../settings/profile-udpate/dto/profile.dto';
import { UserSignupReqDto, UserSignupResDto } from '../dto/create-user.dto';
import { LoginReqDto, LoginResDto } from '../dto/login.dto';
import {
  ForgotPasswordReqDto,
  PasswordSetResDto,
  ResetForgottenPasswordReqDto,
  VerifyEmailReqDto
} from '../dto/password-reset.dto';
import { UserRole } from '../enum/user-role.enum';

class AuthRepositoryImpl {
  public login = createAsyncThunk('auth/login', this._login);
  private async _login(userData: LoginReqDto): Promise<LoginResDto> {
    const res = await httpClient.post<LoginResDto>('/auth/login', userData);

    return res.data;
  }

  public loginGoogleRedirect = createAsyncThunk(
    'auth/login/google/redirect',
    this._loginGoogleRedirect
  );

  private async _loginGoogleRedirect(queryParams: Record<string, string>): Promise<LoginResDto> {
    const res = await httpClient.get<LoginResDto>('/auth/google/redirect', { params: queryParams });

    return res.data;
  }

  public logout = createAsyncThunk('auth/logout', this._logout);
  private async _logout() {
    await httpClient.get('auth/logout');
  }

  public signup = createAsyncThunk('auth/signup', this._signup);
  private async _signup(userData: UserSignupReqDto) {
    const { data } = await httpClient.post<UserSignupResDto>('/users/signup', userData);
    return data;
  }

  public async verifyEmail(userData: VerifyEmailReqDto) {
    const { data } = await httpClient.put<PasswordSetResDto>('/auth/verify-email', userData);
    return data;
  }

  public forgetPassword = createAsyncThunk('auth/forget-password', this._forgetPassword);
  private async _forgetPassword(userData: ForgotPasswordReqDto) {
    const { data } = await httpClient.post('/users/forgot-password', userData);
    return data;
  }

  public async resetPassword(userData: ResetForgottenPasswordReqDto) {
    const { data } = await httpClient.put('/users/forgot-password/reset', userData);
    return data;
  }

  public switchRole = createAsyncThunk('auth/switch-role', this._switchRole);
  private async _switchRole(role: UserRole) {
    await httpClient.get('auth/switch-roles', {
      headers: {
        'user-role': role
      }
    });
  }

  public async getProfile(): Promise<UserProfileDto> {
    const { data } = await httpClient.get<UserProfileDto>('users/profile');
    return data;
  }

  public updateProfile = createAsyncThunk('auth/updateProfile', this._updateProfile);
  private async _updateProfile(profileData: UpdateUserProfileDto): Promise<UserProfileDto> {
    const { data } = await httpClient.put<UserProfileDto>('users/profile', profileData);
    return data;
  }
}

export const AuthRepo = new AuthRepositoryImpl();
