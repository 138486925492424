import { ReactNode } from 'react';

import styled from '@emotion/styled';
import MuiDialogActions from '@mui/material/DialogActions';

interface BaseDialogActionsProps {
  disableShadow?: boolean;
}
const BaseDialogActions = styled(MuiDialogActions)<BaseDialogActionsProps>(
  ({ theme, disableShadow }) => ({
    boxShadow: theme.shadow.shadow_10_reverse,
    display: 'flex',
    gap: theme.spacing(6),
    alignItems: 'center',
    '&>:not(:first-of-type)': {
      marginLeft: '0px'
    },
    ...(disableShadow && {
      boxShadow: 'none'
    })
  })
);

interface DialogActionsProps extends BaseDialogActionsProps {
  className?: string;
  children: ReactNode;
}

const DialogActions: React.FC<DialogActionsProps> = ({ className, ...props }) => {
  return <BaseDialogActions className={`py-3 px-8 ${className}`} {...props} />;
};

export default DialogActions;
