import styled from '@emotion/styled';
import Icon from '@icon';
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';

type Severity = 'success' | 'error' | 'warning' | 'info';

interface AlertProps {
  severity?: Severity;
  onClose?: () => void;
  action?: React.ReactNode;
  showBorder?: boolean;
  children: React.ReactNode;
}

interface CustomAlertProps {
  showBorder?: boolean;
}

const CustomAlert = styled((props: MuiAlertProps) => <MuiAlert {...props} />)<CustomAlertProps>(
  ({ theme, severity, showBorder }) => ({
    padding: '5px 32px',
    fontSize: theme.typography.caption.fontSize,
    border: showBorder ? `1px solid ${theme.palette[severity!].main}` : 'none',
    borderRadius: showBorder ? '6px' : '0px'
  })
);

const Alert: React.FC<AlertProps> = ({ children, severity, showBorder, ...props }) => {
  return (
    <CustomAlert
      className='items-center'
      severity={severity}
      showBorder={showBorder}
      icon={severity && <Icon iconKey={severity} color={severity} />}
      {...props}
    >
      {children}
    </CustomAlert>
  );
};

Alert.defaultProps = {
  severity: 'success',
  showBorder: true
};
export default Alert;
