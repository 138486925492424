import { useState, memo } from 'react';

import { DateFormat, DateProvider, DateX } from '@date';
import Icon from '@icon';
import { PopperProps } from '@mui/material/Popper/Popper';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

import { IconButton } from '../button';
import { TextField } from '../input';
import { TextFieldProps } from '../input/text-field';

type ITextFieldProps = Omit<TextFieldProps, 'onChange'>;

interface DatePickerProps extends ITextFieldProps {
  open?: boolean;
  label?: string;
  value?: DateX | null;
  onChange?: (value: DateX | null) => void;
  disableFuture?: boolean;
  disablePast?: boolean;
  shouldDisableDate?: (value: DateX) => boolean;
  shouldDisableYear?: (value: DateX) => boolean;
  renderInput?: (inputProps: TextFieldProps) => JSX.Element;
  onClose?: () => void;
  dateFormat?: DateFormat;
  placeholder?: string;
  PopperProps?: Partial<PopperProps>;
}

const DatePicker: React.FC<DatePickerProps> = ({
  open,
  value,
  onChange,
  shouldDisableDate,
  shouldDisableYear,
  disableFuture,
  disablePast,
  disabled,
  renderInput,
  onClose,
  dateFormat,
  placeholder,
  PopperProps,
  ...props
}) => {
  const [localOpen, setLocalOpen] = useState(false);

  function handleChange(value: DateProvider | null): void {
    if (value) {
      const newValue = DateX.fromProvider(value);
      onChange?.(newValue);
    } else {
      onChange?.(null);
    }
  }

  return (
    <MuiDatePicker
      value={value ? new DateX(value).toDateProvider() : null}
      onChange={handleChange}
      PopperProps={PopperProps}
      renderInput={(inputProps) =>
        renderInput ? (
          renderInput(inputProps)
        ) : (
          <TextField
            {...props}
            {...inputProps}
            suffix={
              <IconButton onClick={() => setLocalOpen(true)}>
                <Icon iconKey='calendar' size='normal' />
              </IconButton>
            }
            onClick={() => setLocalOpen(true)}
            inputProps={{
              ...inputProps.inputProps,
              ...(placeholder && { placeholder }),
              readOnly: true
            }}
          />
        )
      }
      open={open ?? localOpen}
      onClose={() => {
        setLocalOpen(false);
        onClose?.();
      }}
      {...(shouldDisableDate && {
        shouldDisableDate: (value) => shouldDisableDate(DateX.fromProvider(value))
      })}
      {...(shouldDisableYear && {
        shouldDisableYear: (value) => shouldDisableYear(DateX.fromProvider(value))
      })}
      disableFuture={disableFuture}
      disablePast={disablePast}
      disabled={disabled}
      inputFormat={dateFormat || DateFormat.dateMonthYearDay}
      InputProps={{ readOnly: true }}
    />
  );
};

export default memo(DatePicker);
