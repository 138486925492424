
interface DispatchEventProps {
  target: HTMLInputElement,
  setter: 'checked' | 'value',
  value: boolean | string | number
  event: 'onClick' | 'onChange'
}

const EventMapping: Record<DispatchEventProps['event'], string> = {
  onChange: 'input',
  onClick: 'click'
}

export class EventUtil {
  public static dispatchEvent({ event, setter, value, target }: DispatchEventProps): void {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      setter
    )!.set

    nativeInputValueSetter?.call(target, value)

    const ev = new Event(EventMapping[event], { bubbles: true })
    target.dispatchEvent?.(ev)
  }
}
