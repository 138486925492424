import styled from '@emotion/styled'

interface HrProps {
  orientation?: 'vertical' | 'horizontal'
}

export const Hr = styled.hr<HrProps>(({ theme, orientation }) => ({
  backgroundColor: theme.palette.grey[300],
  height: 1,
  width: '100%',

  ...(orientation === 'vertical' && {
    height: '100%',
    width: 1,
    alignSelf: 'stretch'
  })
}))
