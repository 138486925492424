import { combineReducers } from '@reduxjs/toolkit';

import { AgentConfigSlice } from '../../agent/service/agent.slice';

import { ConfigSlice } from './config.slice';

export const configReducer = combineReducers({
  agent: AgentConfigSlice.reducer,
  organization: ConfigSlice.reducer
});
