import { Button } from '@ui';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';

import FormWrapper from '../body/form-wrapper';

const CheckMailSetPassword: React.FC = () => {
  const navigate = useNavigate();

  async function resendInvite() {
    console.log('not implemented');
  }

  return (
    <>
      <FormWrapper
        heading='Check your email'
        description={
          <>
            We have sent a password reset <br />
            link to your email address
          </>
        }
        footer={{
          title: 'Didn’t receive the email? ',
          buttonText: 'Resend Invite',
          onClick: resendInvite
        }}
      >
        <Button
          variant='contained'
          color='primary'
          onClick={() => navigate(routes.auth.login)}
          fullWidth={true}
        >
          Back to Login
        </Button>
      </FormWrapper>
    </>
  );
};

export default CheckMailSetPassword;
