import httpClient from '../../../utils/http-client';
import { createAsyncThunk } from '../../common/hooks/create-async-thunk';
import { UserConfigDto } from '../dto/user-config.dto';

class ConfigRepoImpl {
  public getUserConfig = createAsyncThunk('config/user-config', this._getUserConfig);
  private async _getUserConfig(): Promise<UserConfigDto> {
    const { data } = await httpClient.get<UserConfigDto>('users/config');
    return data;
  }
}

export const ConfigRepo = new ConfigRepoImpl();
