export class CurrencyUtil {
  public static symbol = '₹';
  public static decimalLimit = 2;
  public static multiplier = 100;
  public static currency = 'INR';
  public static locale = 'en-In';

  private static formatterStyle = {
    style: 'currency',
    currency: this.currency
  };

  public static formatter = new Intl.NumberFormat(this.locale, this.formatterStyle);

  public static nonDecimalFormatter = new Intl.NumberFormat(this.locale, {
    ...this.formatterStyle,
    minimumFractionDigits: 0
  });

  public static nonDecimalDisplay(value?: number | null): string | undefined {
    if (value === null || value === undefined) {
      return '';
    }

    return this.nonDecimalFormatter.format(value);
  }

  public static toDisplay(value?: string | number | null): string | undefined {
    if (value === null || value === undefined) {
      return '';
    }

    const absoluteValue = Math.abs(CurrencyUtil.fromApi(value)!);

    const formattedValue = this.formatter.format(absoluteValue);

    if (+value < 0) {
      return `(-) ${formattedValue}`;
    } else {
      return formattedValue;
    }
  }

  public static toApi(value?: string | number | null): number | undefined {
    if (value === null || value === undefined || value === '') {
      return undefined;
    }

    return parseInt((+value * this.multiplier).toString());
  }

  public static fromApi(value?: number | string | null): number | undefined {
    if (value === null || value === undefined || value === '') {
      return undefined;
    }

    return +(+value / this.multiplier).toFixed(CurrencyUtil.decimalLimit);
  }
}
