import styled from '@emotion/styled';

import Radio from './radio';

interface BaseRadioBoxProps {
  checked?: boolean;
  fullWidth?: boolean;
}

const BaseRadioBox = styled(Radio)<BaseRadioBoxProps>(({ theme, checked, fullWidth }) => ({
  border: `1px solid ${checked ? theme.palette.primary.main : theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  margin: 0,
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  '> .MuiFormControlLabel-label': {
    fontSize: '14px',
    ...(!checked && { color: theme.palette.grey[800] })
  },
  ...(checked && { backgroundColor: theme.palette.primary.light }),
  ...(fullWidth && { width: '100%' })
}));

export { BaseRadioBox };
