import { useSnackbar } from '@ui';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { routes } from '../../../routes/routes';
import { HttpClientUtil } from '../../../utils/http-client';
import { ResetForgottenPasswordReqDto, SetPasswordReqDto } from '../dto/password-reset.dto';
import { AuthRepo } from '../service/auth-repository';

import SetPasswordForm from './set-password-form';

const ResetPasswordPage: React.FC = () => {
  const [searchParam] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  async function setPassword(data: SetPasswordReqDto) {
    const token = searchParam.get('token');
    const userId = searchParam.get('id');

    if (!token || !userId) {
      enqueueSnackbar('Invalid request', { variant: 'error' });
      return;
    }

    const reqData: ResetForgottenPasswordReqDto = {
      password: data.password,
      token,
      userId
    };

    try {
      await AuthRepo.resetPassword(reqData);
      enqueueSnackbar('Password set successfully', { variant: 'success' });
      navigate(routes.auth.login);
    } catch (error) {
      const msg = HttpClientUtil.getErrorMsgKey(error);
      enqueueSnackbar(msg, { variant: 'error' });
    }
  }

  return <SetPasswordForm onSubmit={setPassword} />;
};

export default ResetPasswordPage;
