import { IsEmail, IsString, IsUUID } from 'class-validator';

class SetPasswordReqDto {
  @IsString()
  password: string;
}

class ForgotPasswordReqDto {
  @IsEmail()
  email: string;
}

class ResetForgottenPasswordReqDto {
  @IsString()
  token: string;

  @IsUUID()
  userId: string;

  @IsString()
  password: string;
}

export class VerifyEmailReqDto {
  @IsString()
  token: string;

  @IsEmail()
  email: string;

  @IsString()
  password: string;
}

export class PasswordSetResDto {
  token: string;
}

export { ForgotPasswordReqDto, ResetForgottenPasswordReqDto, SetPasswordReqDto };
