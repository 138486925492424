import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { Env } from '../../env/env';
import { AuthSelector } from '../../modules/auth/service/auth.selector';
import { store } from '../../redux/store';

const headers = {
  appVersionCode: '1',
  clientPlatform: 'WEB'
};

const httpClient = axios.create({
  baseURL: Env.backendBaseUrl,
  headers
});

httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const { token } = AuthSelector.self(store.getState());
  if (token) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (config.headers as any).authorization = `Bearer ${token}`;
  }

  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

export default httpClient;
