import styled from '@emotion/styled';
import Icon from '@icon';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<Icon iconKey='chevron-right' color='primary' />} {...props} />
))(({ theme }) => ({
  backgroundColor: '#ffffff',
  border: 'none',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(6)
  },
  padding: '12px 0px 12px 32px'
}));

const NestedAccordionSummary = styled(StyledAccordionSummary)(() => ({
  padding: '12px 0px 12px 64px'
}));

interface Props {
  children: React.ReactNode;
}

const AccordionSummary: React.FC<Props> = ({ children }) => {
  return <StyledAccordionSummary>{children}</StyledAccordionSummary>;
};

export { AccordionSummary, NestedAccordionSummary };
