import styled from '@emotion/styled';
import MuiDialogTitle from '@mui/material/DialogTitle';

import Icon from '../../icon';
import IconButton from '../button/icon-button';

interface DialogTitleStyledProps {
  disableBorder?: boolean;
}
const DialogTitleStyled = styled(MuiDialogTitle)<DialogTitleStyledProps>(
  ({ theme, disableBorder }) => ({
    borderBottom: `1px solid ${theme.palette.grey[300]}`,

    ...(disableBorder && {
      borderBottom: 'none'
    })
  })
);

interface DialogTitleProps extends DialogTitleStyledProps {
  onClose?: () => void;
  onGoBack?: () => void;
  className?: string;
  children: React.ReactNode;
}

const DialogTitle: React.FC<DialogTitleProps> = ({
  children,
  onClose,
  onGoBack,
  className,
  ...restProps
}) => {
  return (
    <DialogTitleStyled className={`flex justify-between py-6 px-8 ${className}`} {...restProps}>
      <div className='flex items-center gap-1'>
        {onGoBack && (
          <IconButton onClick={onGoBack} color='default'>
            <Icon iconKey='arrow-left' size='medium' />
          </IconButton>
        )}

        <span>{children}</span>
      </div>

      {onClose && (
        <IconButton onClick={onClose} className='-mr-3 '>
          <Icon iconKey='cross' size='medium' />
        </IconButton>
      )}
    </DialogTitleStyled>
  );
};

export default DialogTitle;
