import { RadioProps } from './radio';
import { BaseRadioBox } from './style';

interface RadioBoxProps extends RadioProps {
  fullWidth?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
}

const RadioBox: React.FC<RadioBoxProps> = (props) => {
  return <BaseRadioBox {...props} />;
};

RadioBox.defaultProps = {
  fullWidth: true
};

export default RadioBox;
