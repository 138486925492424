import styled from '@emotion/styled'

import { theme } from '../../../styles/theme'

interface PopoverItemWrapperProps {
  selected?: boolean
}

export const ActionElementWrapper = styled.div(() => ({
  padding: '8px 16px'
}))

export const PopoverItemWrapper = styled.div<PopoverItemWrapperProps>(
  ({ selected }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
    backgroundColor: selected ? theme.palette.grey[500] : 'transparent',
    padding: '16px',
    cursor: 'pointer',
    pointerEvents: selected ? 'none' : 'auto',

    '> :last-child': {
      fontsize: '12px',
      fontWeight: 600
    },

    '> :first-of-type': {
      fontsize: '14px',
      fontWeight: 400
    }
  })
)

interface ContentProps {
  width?: number
}
export const ContentWrapper = styled.div<ContentProps>(({ width }) => ({
  width: `${width}px`
}))
