import { forwardRef, useRef } from 'react';

import { useForkRef } from '@mui/material';

import Icon from '../../icon';
import { EventUtil } from '../utils/event-util';

import { BaseCheckbox } from './style';

interface CheckboxProps {
  className?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  disabled?: boolean;
  value?: string;
}

const Checkbox: React.ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = (props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const forkedRef = useForkRef(inputRef, ref);

  function onClick(e: React.MouseEvent<HTMLButtonElement>): void {
    if (e.target !== e.currentTarget || !inputRef.current) {
      return;
    }

    const newValue = !inputRef.current.checked;
    EventUtil.dispatchEvent({
      target: inputRef.current,
      event: 'onClick',
      setter: 'checked',
      value: newValue
    });
  }

  return (
    <BaseCheckbox onClick={onClick} disabled={props.disabled}>
      <div>
        <input {...props} type='checkbox' ref={forkedRef} />
        <span>
          <Icon iconKey='check' color='inherit' className='check-icon' />
        </span>
      </div>
    </BaseCheckbox>
  );
};

export default forwardRef(Checkbox);
