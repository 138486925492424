import { Spin } from '@ui'

interface PageLoaderProps {}

const PageLoader: React.FC<PageLoaderProps> = () => {
  return (
    <div className='w-full flex justify-center py-24'>
      <Spin className='w-8 h-8' />
    </div>
  )
}

export default PageLoader
