import { createSlice } from '@reduxjs/toolkit';

import { ConfigRepo } from '../../config/service/config.repo';
import { UserDto } from '../../user/dto/user.dto';
import { UserRole } from '../enum/user-role.enum';

import { AuthRepo } from './auth-repository';

export interface AuthState {
  isLoggedIn: boolean;
  user?: UserDto;
  token?: string;
  role: UserRole;
}

const initialState: AuthState = {
  isLoggedIn: false,
  role: UserRole.Admin
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AuthRepo.login.fulfilled, (state, { payload }) => {
        state.isLoggedIn = true;
        state.user = payload.user;
        state.token = payload.token;
        state.role = payload.user.userRoles.includes(UserRole.Admin)
          ? UserRole.Admin
          : UserRole.Agent;
      })
      .addCase(AuthRepo.loginGoogleRedirect.fulfilled, (state, { payload }) => {
        state.isLoggedIn = true;
        state.user = payload.user;
        state.token = payload.token;
        state.role = payload.user.userRoles.includes(UserRole.Admin)
          ? UserRole.Admin
          : UserRole.Agent;
      })
      .addCase(ConfigRepo.getUserConfig.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.role = payload.currentRole;
      })
      .addCase(AuthRepo.switchRole.fulfilled, (state, action) => {
        state.role = action.meta.arg;
      })
      .addCase(AuthRepo.logout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.token = undefined;
        state.user = undefined;
        localStorage.clear();
      });
  }
});
