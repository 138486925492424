import {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  createAsyncThunk as createAsyncThunkOrg
} from '@reduxjs/toolkit';

export const createAsyncThunk = <Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, {}>,
  options?: AsyncThunkOptions<ThunkArg, {}>
): AsyncThunk<Returned, ThunkArg, {}> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _payloadCreator: any = async (...args: any[]) => {
    const thunkApi = args[1];
    try {
      // @ts-ignore
      const response = await payloadCreator(...args);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  };

  return createAsyncThunkOrg(typePrefix, _payloadCreator, options);
};
