import { HTMLInputTypeAttribute, memo, ReactNode, Ref, RefObject } from 'react';

import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { InputBaseComponentProps } from '@mui/material/InputBase';

import Icon from '../../icon';
import IconButton from '../button/icon-button';
import Tooltip from '../tooltip';
import Typography from '../typography';

import { TextFieldValidationStatus } from './interface/validation-status.interface';
import TextFieldHelperText from './text-field-helper-text';
import { InputBaseStyled } from './text-field.style';

export interface TextFieldProps {
  name?: string;
  label?: ReactNode;
  disabled?: boolean;
  placeholder?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  fullWidth?: boolean;
  onInput?: React.FormEventHandler<HTMLDivElement>;
  inputProps?: InputBaseComponentProps;
  validationStatus?: TextFieldValidationStatus;
  helperText?: React.ReactNode;
  helperTextType?: 'default' | 'tooltip';
  className?: string;
  value?: string | number | unknown;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiline?: boolean;
  rows?: number | string;
  type?: HTMLInputTypeAttribute;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  wrapperRef?: RefObject<HTMLDivElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement>;
  readOnly?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  autoFocus?: boolean;
  autoComplete?: string;
  inputRef?: Ref<HTMLInputElement>;
  minRows?: number | string;
  maxRows?: number | string;
}

const TextField: React.FC<TextFieldProps> = ({
  label,
  prefix,
  suffix,
  helperText,
  helperTextType,
  className,
  ...props
}) => {
  function getHelperTooltipIcon(): JSX.Element {
    const icon = props.validationStatus === 'error' ? 'warning' : 'success';

    return (
      <IconButton color={props.validationStatus}>
        <Icon iconKey={icon} />
      </IconButton>
    );
  }

  return (
    <FormControl variant='standard' fullWidth={props.fullWidth} classes={{ root: className ?? '' }}>
      {label && (
        <Typography variant='caption' color='textSecondary' className='mb-1'>
          {label}
        </Typography>
      )}
      <InputBaseStyled
        {...(suffix && {
          endAdornment: <InputAdornment position='end'>{suffix}</InputAdornment>
        })}
        {...(prefix && {
          startAdornment: <InputAdornment position='start'>{prefix}</InputAdornment>
        })}
        className={`${props.disabled && 'disabled'}`}
        ref={props.wrapperRef}
        autoComplete='off'
        {...(helperTextType === 'tooltip' &&
          helperText && {
            endAdornment: (
              <InputAdornment position='end' classes={{ root: 'mr-1' }}>
                <Tooltip title={helperText} placement='top'>
                  {getHelperTooltipIcon()}
                </Tooltip>
              </InputAdornment>
            )
          })}
        {...props}
      />
      {helperTextType === 'default' && (
        <TextFieldHelperText validationStatus={props.validationStatus}>
          {helperText}
        </TextFieldHelperText>
      )}
    </FormControl>
  );
};

TextField.defaultProps = {
  fullWidth: false,
  helperTextType: 'default'
};

export default memo(TextField);
