import styled from '@emotion/styled'
import MuiLenearProgress from '@mui/material/LinearProgress'

const StyledLinearProgress = styled(MuiLenearProgress)({
  borderRadius: '10px'
})

interface LinearProgressProps {
  value?: number
  variant?: 'determinate'
}

const LinearProgress: React.FC<LinearProgressProps> = (props) => {
  return <StyledLinearProgress {...props} />
}

export default LinearProgress
