import { createSlice } from '@reduxjs/toolkit';

import { AgentStatus } from '../enum/agent-status.enum';

import { AgentRepo } from './repo';

interface State {
  status: AgentStatus;
}

const initialState: State = {
  status: AgentStatus.Unavailable
};

export const AgentConfigSlice = createSlice({
  name: 'agent-config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AgentRepo.changeStatus.fulfilled, (state, action) => {
      state.status = action.meta.arg;
    });
  }
});
