import { useContext } from 'react';

import styled from '@emotion/styled';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { accordionContext } from './accordion.context';

const StyledAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0px'
}));

interface Props {
  children: React.ReactNode;
}

const AccordionDetails: React.FC<Props> = ({ children }) => {
  const { isAccordionDetailOpen } = useContext(accordionContext);
  return <StyledAccordionDetails>{isAccordionDetailOpen && children}</StyledAccordionDetails>;
};

export default AccordionDetails;
